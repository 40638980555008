import { motion } from "framer-motion";
import { BtnArrow } from "../style/icon/hades.iamges";
import { Link } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({ id, text, link }: any) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link to={link}>
        <div className="text-placeholder">
          <span className="text">{text}</span>
        </div>

        <div className="icon-placeholder">
          <span className="icon">
            <BtnArrow />
          </span>
        </div>
      </Link>
    </motion.li>
  );
};
