import { motion } from "framer-motion";
import styled from "styled-components";

export const HeaderWrap = styled.header`
  width: 100%;
  height: 11.2rem;
  padding: 2.6rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .logo {
    cursor: pointer;
    width: 6rem;
    svg {
      max-width: 100%;
    }
  }
  .center {
    cursor: pointer;
    width: 16.7rem;
    svg {
      max-width: 100%;
    }
  }
  nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 64rem;
  }

  .navbar {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    width: 64rem;
    background: transparent;
    backdrop-filter: blur(10px);
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 3rem;
    right: 4rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      min-width: 3rem;
    }
  }

  ul {
    padding: 2rem 8rem;
    position: absolute;
    top: 30rem;
    width: 64rem;
  }

  li {
    list-style: none;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40rem;
    }
  }

  .icon-placeholder {
    width: 5.8rem;
    height: 5.8rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-placeholder {
    font-size: 6rem;
    font-weight: 600;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    svg {
      width: 3.4rem;
      height: 3.4rem;
    }
  }

  .text {
    color: #fef6e4;
  }
  @media screen and (max-width: 768px) {
    height: 70px;
    padding: 32px 19px;
    justify-content: flex-start;
    .logo {
      position: relative;
      z-index: 9999;
      cursor: pointer;
      width: 32px;
      svg {
        max-width: 100%;
      }
    }
    .center {
      cursor: pointer;
      position: relative;
      z-index: 9999;
      width: 89px;
      transform: translateX(-8px);
      svg {
        max-width: 100%;
      }
    }
    nav {
      width: 100%;
    }

    .navbar {
      width: 100%;
    }

    button {
      top: 19px;
      right: 32px;
      width: 32px;
      height: 32px;
      svg {
        min-width: max-content;
      }
    }

    ul {
      padding: 8px 44px;
      top: 78px;
      width: 100%;
    }

    li {
      margin-bottom: 24px;
      a {
        width: 100%;
      }
    }

    .icon-placeholder {
      width: 32px;
      height: 32px;
    }

    .text-placeholder {
      font-size: 40px;
    }

    .icon {
      svg {
        width: 18.76px;
        height: 18.76px;
      }
    }

    .text {
      color: #fef6e4;
    }
  }
`;

// body
export const Container = styled(motion.div)`
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50rem;
  .mobile-contents {
    display: none;
  }
  @media screen and (max-width: 768px) {
    min-height: 124px;
    .mobile-contents {
      padding: 0 32px;
      display: block;
      margin-top: 10px;
      font-size: 20px;
      font-weight: 500;
      color: #818080;
      letter-spacing: -1px;
      text-align: center;
    }
  }
`;

export const BgTextWrap = styled.div`
  overflow: hidden;
  width: 100vw;
  padding-top: 11.2rem;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  align-items: center;
  p {
    margin-top: 8.878rem;
    font-size: 22.8rem;
    font-weight: 600;
    letter-spacing: -0.6px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 0 6rem;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    p {
      margin-top: 20px;
      font-size: 80px;
    }
  }
`;

export const MainBtBox = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  font-size: 2.2rem;
  p {
    color: rgba(13, 80, 242, 1);
    text-align: center;
    font-weight: 600;
  }
  .copy-right {
    color: rgba(205, 204, 204, 1);
    font-weight: 400;
    margin-top: 3.167rem;
  }
  .down-scroll-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    bottom: 32px;
    width: 100%;
    p {
      display: none;
    }
    .down-scroll-arrow {
      display: none;
    }
    .copy-right {
      margin-top: 0;
      font-size: 14px;
      width: 100%;
      text-align: center;
    }
  }
`;

export const HideContents = styled(motion.div)`
  display: block;
  height: calc(100vh - 11.2rem);
  width: 100%;
  position: fixed;
  bottom: 0;
  .grid-container {
    display: grid;
    grid-template-areas:
      "portfolio portfolio about about"
      "portfolio portfolio product contact";
    grid-template-columns: repeat(4, 1fr); /* 4개의 동일한 비율 컬럼 */
    grid-template-rows: repeat(2, 1fr); /* 2개의 동일한 비율 행 */
    gap: 2rem; /* 각 항목 간의 간격 */
    width: 90%;
    max-width: 160rem;
    margin: 0 auto;
    align-items: stretch;
    max-height: 79rem;
    height: 100%;
    padding: 5.564rem 0 5rem;
    .grid-item {
      border-radius: 2rem;
      padding: 4rem 3.6rem;
      color: black;
      font-size: 1rem;
      display: flex;
      background: linear-gradient(
        104.91deg,
        rgba(217, 217, 217, 0.9) 4.24%,
        rgba(243, 243, 243, 0.9) 95.76%
      );
      backdrop-filter: blur(10px);
      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
      }
      p {
        white-space: pre-wrap;
        line-height: 1.44;
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
          width: 5.8rem;
          height: 5.8rem;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 50%;
          svg {
            width: 3.4rem;
            height: 3.4rem;
          }
        }
      }
      &:hover {
        box-shadow: 0 2px 4px rgba(128, 128, 128, 0.3);
        svg {
          animation: rotateArrow 1s linear infinite; /* 애니메이션 추가 */
        }
        h2 {
          text-decoration: underline;
        }
      }

      @keyframes rotateArrow {
        from {
          transform: rotate3d(1, 0, 0, 0deg); /* 시작 상태 */
        }
        to {
          transform: rotate3d(1, 0, 0, 360deg); /* 360도 회전 */
        }
      }
    }
    .portfolio {
      grid-area: portfolio;
      background: linear-gradient(
        135.77deg,
        rgba(13, 80, 242, 0.9) 1.3%,
        rgba(127, 164, 255, 0.7) 100%
      );
      backdrop-filter: blur(10px);
      color: white;
      h2 {
        font-size: 8rem;
        font-weight: 600;
      }
      p {
        font-size: 4.8rem;
        font-weight: 500;
        line-height: 1.2;
      }
    }

    .about {
      grid-area: about;
      h2 {
        font-size: 5.6rem;
        font-weight: 600;
      }
      p {
        font-size: 3.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.mainTextGray};
      }
    }

    .product {
      grid-area: product;
      h2 {
        font-size: 5.6rem;
        font-weight: 600;
      }
      p {
        font-size: 2.8rem;
        font-weight: 500;
        color: ${(props) => props.theme.mainTextGray};
      }
    }

    .contact {
      grid-area: contact;
      h2 {
        font-size: 5.6rem;
        font-weight: 600;
      }
      p {
        font-size: 2.8rem;
        font-weight: 500;
        color: ${(props) => props.theme.mainTextGray};
      }
    }

    .grid-item:hover {
      transform: translateY(-5px);
      transition: transform 0.3s;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const VirtualAreaDiv = styled.div`
  height: calc(100vh + 100px);
  background: transparent;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileHideCon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    margin-top: 100px;
    display: flex;
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      li {
        padding: 0 12px;
        a {
          width: 100%;
          border-radius: 36px;
          padding: 13.5px 20px;
          background: linear-gradient(
            104.91deg,
            rgba(217, 217, 217, 0.9) 4.24%,
            rgba(243, 243, 243, 0.9) 95.76%
          );
          display: flex;
          width: 100%;

          .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h2 {
              font-size: 32px;
              font-weight: 600;
            }
            span {
              width: 31px;
              height: 31px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: white;
              border-radius: 50%;
              svg {
                max-width: 100%;
                width: 18px;
                height: 18px;
              }
            }
          }
        }
        &:first-child {
          a {
            color: white;
            background: linear-gradient(
              135.77deg,
              rgba(13, 80, 242, 0.9) 1.3%,
              rgba(127, 164, 255, 0.9) 100%
            );
          }
        }
      }
    }
  }
`;

// portfolio
export const PortfolioContainer = styled(motion.div)`
  padding: 10rem 0;
  max-width: 160rem;
  margin: 11.2rem auto 0;
  @media screen and (max-width: 768px) {
    padding: 0 22px;
    max-width: 100%;
    margin: 70px auto 0;
  }
`;

export const TitleWrap = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 8rem;
    font-weight: 600;
  }
  .filter-box {
    padding: 2rem 4.8rem;
    ul {
      display: flex;
      align-items: center;
      gap: 4rem;
      li {
        font-size: 3.2rem;
        font-weight: 600;
        color: #cdcccc;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    h3 {
      font-size: 40px;
    }
    .filter-box {
      padding: 0;
      margin-top: 10px;
      ul {
        gap: 8px;
        li {
          font-size: 14px;
          padding: 6px 16px;
          border: 1px solid #cdcccc;
          border-radius: 16px;
          &.active {
            border-color: #040000;
          }
        }
      }
    }
  }
`;

export const PortfolioGridBox = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 6rem;
  gap: 6rem 2rem;
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2.8rem;
    border-radius: 4rem;
    overflow: hidden;
    min-height: 50rem;
    .img-item {
      width: 100%;
      height: 30rem;
      border-radius: 4rem;
      overflow: hidden;
      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sub-item {
      max-width: 34rem;
      margin: 0 auto;
      font-family: "Pretendard";
      h5 {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1.43;
        font-family: inherit;
        color: ${(props) => props.theme.portfolioTitleBlack};
        margin-bottom: 0.4rem;
        line-height: 1.43;
      }
      p {
        font-family: inherit;
        font-size: 2.2rem;
        font-weight: 400;
        color: ${(props) => props.theme.portfolioTextGray};
        margin-bottom: 0.2rem;
        line-height: 1.43;
      }
      span {
        font-family: inherit;
        font-size: 1.8rem;
        font-weight: 400;
        color: ${(props) => props.theme.PortfolioSmTextGray};
        letter-spacing: -1px;
        white-space: pre-wrap;
        display: block;
        line-height: 1.43;
      }
    }
    &:hover {
      box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
      .img-item {
        padding: 2px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
    gap: 0;
    border-top: 1px solid #cdcccc;
    margin-bottom: 50px;
    .grid-item {
      flex-direction: row-reverse;
      gap: 12px;
      border-radius: 0;
      min-height: 150px;
      padding: 16px 10px;
      border-bottom: 1px solid #cdcccc;
      .img-item {
        width: 100px;
        height: 100%;
        flex-basis: 100px;
        img {
          aspect-ratio: 1 / 1.2;
        }
      }
      .sub-item {
        max-width: 200px;
        flex: 1;
        h5 {
          font-size: 18px;
          line-height: 1.43;
        }
        p {
          font-size: 14px;
          height: 31px;
          letter-spacing: -0.8px;
        }
        span {
          font-size: 12px;
          letter-spacing: -1px;
          white-space: normal;
        }
      }
    }
  }
`;

export const AboutContainer = styled(motion.div)`
  .first-section {
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: #f0f0f0;
  }
  @media screen and (max-width: 768px) {
    .first-section {
      position: static;
      height: auto;
    }
  }
`;

export const AboutSectionOne = styled.section`
  height: 100vh;
  background: linear-gradient(
    135.77deg,
    rgba(13, 80, 242, 0.9) 1.3%,
    rgba(127, 164, 255, 0.9) 100%
  );
  position: relative;
  .bg-img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 118.7rem;
    img {
      max-width: 100%;
    }
  }
  .about-text {
    position: absolute;
    bottom: 32rem;
    left: 15rem;
    color: white;
    p {
      color: #cae7ff;
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 8rem;
      font-weight: 700;
      white-space: pre-wrap;
      line-height: 1.2;
    }
  }
  .mobile-contents {
    display: none;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 640px;
    position: relative;
    .bg-img {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 100%;
      img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
    .about-text {
      display: none;
    }
    .mobile-contents {
      display: block;
      padding: 70px 32px;
      h2 {
        margin-top: 8px;
        font-size: 40px;
        font-weight: 700;
        color: white;
        white-space: pre-wrap;
        line-height: 1.43;
        letter-spacing: -1.2px;
      }
    }
  }
`;

export const AboutSectionTwo = styled.section`
  min-height: 100vh;
  z-index: 10;
  position: relative;
  .section-inner {
    background-color: white;
    padding: 16rem 14rem;
    .logo-mean {
      margin-top: 10rem;
      margin-bottom: 8rem;
      display: flex;
      gap: 13.5rem;
      align-items: flex-end;
      .img-box {
        max-width: 48.05rem;
        margin-left: 6rem;
        img {
          max-width: 100%;
        }
      }
      .text-box {
        p {
          font-size: 4.8rem;
          font-weight: 700;
          line-height: 1.43;
          margin-bottom: 2rem;
          color: ${(props) => props.theme.blackColor};
        }
        ul {
          li {
            font-family: "Pretendard";
            margin-bottom: 0.8rem;
            font-size: 3.2rem;
            color: #818080;
            list-style: auto;
            margin-left: 4.8rem;
          }
          li:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .work-box {
      max-width: 160rem;
      border-radius: 4rem;
      border: 1px solid #cdcccc;
      padding: 8.45rem 18.5rem;
      ul {
        display: flex;
        gap: 9rem;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          .img-box {
            max-width: 24rem;
            img {
              max-width: 100%;
            }
          }
          span {
            font-size: 2.2rem;
            line-height: 1.43;
            font-weight: 500;
          }
        }
      }
    }
  }
  .process-box {
    padding: 24rem 16rem 12rem;
    background-color: #edf7ff;
    overflow: hidden;
    h2 {
      font-size: 8rem;
      font-weight: 600;
      color: ${(props) => props.theme.blackColor};
    }
    .process {
      margin-top: 4rem;
    }
  }

  .team-box {
    padding: 24rem 16rem 12rem;
    background-color: white;
    overflow: hidden;
    height: 100vh;
    h2 {
      margin-top: 4rem;
      margin-bottom: 6rem;
      font-size: 8rem;
      font-weight: 600;
      color: ${(props) => props.theme.blackColor};
    }
  }

  @media screen and (max-width: 768px) {
    min-height: auto;
    .section-inner {
      background-color: white;
      padding: 80px 22px 60px;
      .logo-mean {
        margin-top: 20px;
        margin-bottom: 12px;
        gap: 28px;
        align-items: center;
        flex-direction: column;
        .img-box {
          max-width: 250px;
          margin-left: 0;
        }
        .text-box {
          p {
            font-size: 22px;
            margin-bottom: 12px;
          }
          ul {
            li {
              font-family: "Pretendard";
              margin-bottom: 4px;
              font-size: 12px;
              margin-left: 0;
              letter-spacing: -0.8px;
              text-align: center;
              list-style: none;
            }
            li:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .work-box {
        max-width: 100%;
        border-radius: 11px;
        padding: 18.82px 15.5px;
        ul {
          display: flex;
          gap: 4px;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            .img-box {
              max-width: none;
              img {
                max-width: 100%;
              }
            }
            span {
              font-size: 8px;
              letter-spacing: -1px;
            }
          }
        }
      }
    }
    .process-box {
      padding: 60px 32px;
      h2 {
        font-size: 40px;
      }
      .process {
        margin-top: 32px;
      }
    }

    .team-box {
      padding: 40px 0 80px;
      height: auto;
      h2 {
        padding: 40px 32px 20px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 40px;
      }
    }
  }
`;

export const ProcessSection = styled.section`
  min-height: 26.6rem;
`;

export const Timeline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: 8rem;
  &::before {
    content: "";
    position: absolute;
    top: 1.9rem;
    left: 1.5rem;
    right: 0;
    height: 2px;
    background-color: ${(props) => props.theme.signatureColor};
    z-index: 1;
    width: 150%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    &::before {
      right: auto;
      height: 150%;
      width: 2px;
      left: 11px;
      top: 12px;
    }
  }
`;

export const Step = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .circle-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    .lg-circle {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      background-color: ${(props) => props.theme.signatureColor};
      opacity: 0.5;
    }
    .sm-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: ${(props) => props.theme.signatureColor};
    }
  }
  @media screen and (max-width: 768px) {
    gap: 10px;
    display: grid;
    grid-template-columns: 24px auto;
    .circle-box {
      margin-bottom: 0;
      .lg-circle {
        width: 24px;
        height: 24px;
      }
      .sm-circle {
        width: 12px;
        height: 12px;
      }
    }
    div:first-child {
      grid-row: 1/3;
    }
  }
`;

export const StepTitle = styled.h3`
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const StepDescription = styled.ul`
  list-style: disc;
  color: #4f4d4d;
  font-size: 2.8rem;
  margin-left: 4rem;
  font-weight: 500;

  li {
    margin-bottom: 0.8rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 0;
    li {
      margin-bottom: 2px;
      margin-left: 14px;
    }
  }
`;

export const TeamSection = styled.section`
  ul {
    display: flex;
    gap: 2rem;
    align-items: center;
    li {
      max-width: 38.5rem;
      img {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 32px;
    overflow-y: hidden;
    overflow-x: scroll;
    ul {
      display: flex;
      gap: 12px;
      align-items: center;
      width: 636px;
      li {
        max-width: 150px;
        img {
          max-width: 100%;
        }
      }
    }
  }
`;

export const ProductContainer = styled(motion.div)`
  padding: 30rem 16rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    padding: 2rem;
    border: 1px solid #dcdddd;
    border-radius: 2rem;
    margin-bottom: 8rem;
    .img-box {
      max-width: 40rem;
      overflow: hidden;
      border-radius: 2rem;
      img {
        max-width: 100%;
      }
    }
    .text {
      text-align: center;
      h2 {
        font-size: 12rem;
        font-weight: 600;
        white-space: pre-wrap;
        letter-spacing: -1px;
        margin: 0 4rem 8rem;
      }
      p {
        font-size: 4rem;
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .box {
      flex-direction: column;
      gap: 12px;
      padding: 20px;
      border-radius: 14px;
      margin-bottom: 20px;
      .img-box {
        max-width: 100%;
        border-radius: 14px;
      }
      .text {
        h2 {
          font-size: 28px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
`;

export const ContactContainer = styled(motion.div)`
  padding-top: 11.2rem;
  .inner {
    padding: 8rem 16rem;
    min-height: calc(100vh - 11.2rem);
    h2 {
      font-weight: 600;
      font-size: 8rem;
      margin-bottom: 6rem;
      line-height: 1.43;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    .inner {
      padding: 8px 32px 0;
      min-height: calc(100vh - 70px);
      h2 {
        font-size: 40px;
        margin-bottom: 28px;
      }
    }
  }
`;

export const MapWrapper = styled.div`
  height: 42rem;
  @media screen and (max-width: 768px) {
    height: auto;
    margin-bottom: 32px;
  }
`;

export const MapBox = styled.div`
  width: 100%;
  position: relative;
  border-radius: 4rem;
  overflow: hidden;
  height: 100%;
  @media screen and (max-width: 786px) {
    .map {
      height: 300px !important;
    }
  }
`;

export const MapInfo = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: white;
  border-radius: 0.8rem;
  z-index: 15;
  padding: 1.5rem 3rem;
  border: 1px solid #ddd;
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.32);
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    li:first-child {
      font-size: 2rem;
      font-weight: bold;
    }
    li {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 786px) {
    top: auto;
    right: 10px;
    left: 10px;
    bottom: 10px;
    border-radius: 12px;
    ul {
      gap: 4px;
      li:first-child {
        font-size: 16px;
      }
      li {
        font-size: 12px;
        font-family: "Pretendard";
      }
    }
  }
`;

export const ContactGridContainer = styled.div`
  margin-bottom: 6rem;
  form {
    display: grid;
    grid-template-areas:
      "name email call send"
      "subject budget budget send"
      "message message message send";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 8rem 8rem 18.6rem;
    gap: 2rem;
    .grid-item {
      background: linear-gradient(
        104.91deg,
        rgba(217, 217, 217, 0.9) 4.24%,
        rgba(243, 243, 243, 0.9) 95.76%
      );
      border-radius: 4rem;
      .input-box {
        padding: 0 4rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        height: 100%;
        label {
          font-size: 2.2rem;
          font-weight: 400;
          color: ${(props) => props.theme.blackColor};
          flex: 1;
          white-space: nowrap;
        }
        input {
          font-family: "Pretendard";
          width: 22rem;
          border: none;
          outline: none;
          background-color: transparent;
          &:focus-within {
            outline: none;
          }
          font-size: 2.2rem;
          font-weight: 500;
        }
        input::placeholder {
          color: #b4b2b2;
        }
        .select-content {
          font-size: 2.2rem;
          width: 100%;
          .react-select__control {
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
          }
          .react-select__menu {
            border-radius: 4rem;
            padding: 1.4rem;
            z-index: 999999;
            .react-select__option {
              border-radius: 4rem;
            }
            .react-select__option--is-selected {
              background: linear-gradient(
                135.77deg,
                rgba(13, 80, 242, 0.9) 1.3%,
                rgba(127, 164, 255, 0.9) 100%
              );
            }
          }
          .react-select__single-value {
            font-weight: 600;
          }
          .react-select__value-container {
            padding: 2px 0;
            margin: 2px 0;
          }
          .react-select__input-container {
            padding: 2px 0;
            margin: 2px 0;
          }
        }
      }
    }
    /* 각각의 영역 정의 */
    .name {
      grid-area: name;
    }

    .email {
      grid-area: email;
    }

    .call {
      grid-area: call;
    }

    .subject {
      grid-area: subject;
    }

    .budget {
      grid-area: budget;
    }

    .message {
      grid-area: message;
      .input-box {
        padding: 3.2rem 4rem;
        align-items: flex-start;
        label {
          flex: 0;
        }
        textarea {
          flex: 1;
          height: 100%;
          resize: none;
          background-color: transparent;
          border: none;
          padding: 0;
          font-size: 2.2rem;
          &::placeholder {
            color: #b4b2b2;
          }
          &:focus-within {
            outline: none;
          }
        }
      }
    }
    .send {
      grid-area: send;
      background: transparent;
      button {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        border-radius: 4rem;
        background: linear-gradient(
          135.77deg,
          rgba(13, 80, 242, 0.9) 1.3%,
          rgba(127, 164, 255, 0.9) 100%
        );
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: "Poppins";

        .icon-box {
          display: flex;
          width: 6rem;
          height: 6rem;
          margin-bottom: 2rem;
          svg {
            max-width: 100%;
          }
        }
        & > span {
          display: block;
          color: white;
          font-size: 3.2rem;
          font-weight: 600;
        }
        &:hover {
          box-shadow: 2px 2px 2px rgba(217, 217, 217, 0.9);
        }
        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
  @media screen and (max-width: 786px) {
    margin-bottom: 28px;
    form {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .grid-item {
        min-height: 44px;
        border-radius: 22px;
        display: flex;
        align-items: center;
        .input-box {
          padding: 0;
          padding: 0 18px;
          gap: 8px;
          display: flex;
          align-items: center;
          width: 100%;
          label {
            font-size: 14px;
            flex: 0;
            flex-basis: 50px;
            white-space: nowrap;
          }
          input {
            font-family: "Pretendard";
            width: 100%;
            flex: 1;
            border: none;
            outline: none;
            background-color: transparent;
            &:focus-within {
              outline: none;
            }
            font-size: 14px;
          }
          .select-content {
            font-size: 14px;
            width: 100%;
            .react-select__menu {
              border-radius: 22px;
              padding: 14px;
              .react-select__option {
                border-radius: 22px;
              }
            }
            .react-select__value-container {
              padding: 2px 0;
              margin: 2px 0;
            }
            .react-select__input-container {
              padding: 2px 0;
              margin: 2px 0;
            }
          }
        }
      }
      /* 각각의 영역 정의 */
      .name {
        grid-area: name;
      }

      .email {
        grid-area: email;
      }

      .call {
        grid-area: call;
      }

      .subject {
        grid-area: subject;
      }

      .budget {
        grid-area: budget;
      }

      .message {
        grid-area: message;
        height: 88px;
        .input-box {
          padding: 12px 18px;
          align-items: flex-start;
          textarea {
            font-size: 14px;
          }
        }
      }
      .send {
        order: 7;
        button {
          width: 100%;
          height: 44px;
          border-radius: 22px;

          .icon-box {
            display: none;
          }
          & > span {
            display: block;
            color: white;
            font-size: 14px;
          }
          &:hover {
            box-shadow: 2px 2px 2px rgba(217, 217, 217, 0.9);
          }
          &:active {
            transform: scale(0.98);
          }
        }
      }
    }
  }
`;
