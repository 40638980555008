import { motion } from "framer-motion";
import { MenuItem } from "./nav.item";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Items = [
  { id: "0", text: "Home", link: "/" },
  { id: "1", text: "Portfolio", link: "/portfolio" },
  { id: "2", text: "About", link: "/about" },
  { id: "3", text: "Product", link: "product" },
  { id: "4", text: "Contact", link: "contact" },
];

export const Navigation = () => (
  <motion.ul variants={variants}>
    {Items.map((item) => (
      <MenuItem id={item.id} key={item.id} text={item.text} link={item.link} />
    ))}
  </motion.ul>
);
