import { Map, MapMarker } from "react-kakao-maps-sdk";
import useKakaoLoader from "../useKakaoLoader";
import { MapBox, MapInfo, MapWrapper } from "./style/styled.components";

declare global {
  interface Window {
    kakao: any;
  }
}

const MapComponents = () => {
  useKakaoLoader();
  return (
    <MapWrapper>
      <MapBox>
        <Map
          className="map"
          center={{
            lat: 36.485187943380245,
            lng: 127.30094548136854,
          }}
          style={{ width: "100%", height: "100%" }}
        >
          <MapMarker
            position={{
              lat: 36.48512754983417,
              lng: 127.30094548136854,
            }}
          ></MapMarker>
          <MapInfo>
            <ul>
              <li>Hades</li>
              <li>
                주소: 세종특별자치시 한누리대로 2007, 411호(소담동, 퍼스트시티)
              </li>
            </ul>
          </MapInfo>
        </Map>
      </MapBox>
    </MapWrapper>
  );
};

export default MapComponents;
