import { ProductContainer } from "../components/style/styled.components";

const ProductView = () => {
  return (
    <ProductContainer
      initial={{ opacity: 0 }} // 초기 위치와 투명도
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // 애니메이션 속도
    >
      <div className="box">
        <div className="img-box">
          <img src="/56114.jpg" alt="" />
        </div>
        <div className="text">
          <h2>{`Coming Soon!`}</h2>
          <p>페이지 준비 중입니다.</p>
        </div>
      </div>
    </ProductContainer>
  );
};

export default ProductView;
