import { useRef } from "react";
import {
  motion,
  useMotionValue,
  useAnimationFrame,
  useTransform,
} from "framer-motion";
import { wrap } from "framer-motion";
import { BgTextWrap } from "./styled.components";

interface ParallaxProps {
  children: string;
  baseVelocity: number;
}

const ParallaxText = ({ children, baseVelocity }: ParallaxProps) => {
  const baseX = useMotionValue(0); // 텍스트의 x 위치
  const directionFactor = useRef<number>(1); // 방향 제어

  useAnimationFrame((t, delta) => {
    const moveBy = directionFactor.current * baseVelocity * (delta / 1000); // 속도 계산
    baseX.set(baseX.get() + moveBy); // x 위치 업데이트
  });

  // 텍스트가 일정 범위에서 반복되도록 wrap 적용
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  return (
    <div className="parallax">
      <motion.p className="scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.p>
    </div>
  );
};

export default ParallaxText;
