import { useState } from "react";
import PortfolioItems from "../components/portfolio.items";
import {
  PortfolioContainer,
  TitleWrap,
} from "../components/style/styled.components";
import { items } from "../data/portfolio.data";

const PortfolioView = () => {
  const [filter, setFilter] = useState("All"); // 필터 상태 관리

  const handleFilterClick = (filterTag: string) => {
    setFilter(filterTag);
  };

  // 필터링된 데이터 계산
  const filteredItems =
    filter === "All"
      ? items // "All" 선택 시 모든 데이터 표시
      : items.filter(
          (item) =>
            filter === "App"
              ? item.tag.includes("App") // "App" 선택 시 "App&Web"도 포함
              : item.tag === filter // 정확한 태그 매칭
        );
  return (
    <PortfolioContainer
      initial={{ opacity: 0 }} // 초기 위치와 투명도
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // 애니메이션 속도
    >
      <TitleWrap>
        <h3>Portfolio</h3>
        <div className="filter-box">
          <ul>
            {["All", "Web", "App", "Etc."].map((filterTag) => (
              <li
                key={filterTag}
                onClick={() => handleFilterClick(filterTag)} // 필터 클릭 처리
                style={{
                  cursor: "pointer",
                  color: filter === filterTag ? "#040000" : "#cdcccc",
                }}
                className={filter === filterTag ? "active" : ""}
              >
                {filterTag}
              </li>
            ))}
          </ul>
        </div>
      </TitleWrap>
      <PortfolioItems data={filteredItems} key={filter}></PortfolioItems>
    </PortfolioContainer>
  );
};

export default PortfolioView;
