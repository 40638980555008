export const HadesLodo = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2461 24.9826C39.944 24.183 42.4593 22.9281 44.7113 21.3026V14.0293H37.2461V24.9826Z"
        fill="#0D50F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.667 21.2763C18.919 22.9074 21.4343 24.1679 24.1322 24.9713V14.0293H16.667V21.2763Z"
        fill="#0D50F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4042 24.6985L50.5014 19.7957C45.5008 25.2008 38.4758 28.5684 30.7077 28.5684C22.9397 28.5684 15.8978 25.1933 10.8952 19.7769L5.97363 24.6985C9.03083 27.7613 12.6524 30.2598 16.6672 32.0264V46.0274H24.1324V34.3498C26.2565 34.7543 28.4482 34.9688 30.6889 34.9688C32.9296 34.9688 35.1214 34.7543 37.2454 34.3498V46.0293H44.7106V32.0264C48.7236 30.2598 52.347 27.7613 55.4024 24.6985H55.4042Z"
        fill="#0D50F2"
      />
    </svg>
  );
};

export const HadesText = () => {
  return (
    <svg
      width="167"
      height="61"
      viewBox="0 0 167 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5761 14.6284V27.3783H22.1162V14.6284H15.1387V45.813H22.1162V33.0631H34.5761V45.813H41.5536V14.6284H34.5761Z"
        fill="#040000"
      />
      <path
        d="M70.4023 20.8297H64.1449V24.3339C63.3172 23.209 62.2068 22.2773 60.8157 21.5371C59.4537 20.7969 57.8566 20.4268 56.0224 20.4268C53.8655 20.4268 51.9056 20.9446 50.1443 21.9802C48.4123 23.0157 47.0303 24.4944 45.9983 26.4179C44.9955 28.3414 44.4941 30.5457 44.4941 33.0308C44.4941 35.5158 44.9955 37.7803 45.9983 39.733C47.0303 41.6565 48.4141 43.1497 50.1443 44.2163C51.9056 45.281 53.8491 45.8134 55.9768 45.8134C57.811 45.8134 59.4246 45.4433 60.8139 44.7031C62.2342 43.9337 63.3427 42.971 64.1431 41.8187V45.4287H71.1206V20.8297H70.4023ZM63.2789 36.9325C62.7009 37.9717 61.9224 38.7794 60.9415 39.3574C59.9606 39.9062 58.9068 40.1796 57.7819 40.1796C56.6569 40.1796 55.645 39.8916 54.6641 39.3136C53.7106 38.7356 52.9321 37.9134 52.3267 36.845C51.7488 35.7474 51.4607 34.4784 51.4607 33.0344C51.4607 31.5904 51.7488 30.3506 52.3267 29.3114C52.9321 28.243 53.7124 27.4353 54.6641 26.8865C55.6159 26.3377 56.6551 26.0642 57.7819 26.0642C58.9086 26.0642 59.9606 26.3523 60.9415 26.9302C61.9224 27.479 62.7027 28.2867 63.2789 29.3551C63.855 30.3944 64.1449 31.6506 64.1449 33.1219C64.1449 34.5933 63.855 35.8641 63.2789 36.9325Z"
        fill="#040000"
      />
      <path
        d="M93.7095 14.2456V24.3299C92.8818 23.2068 91.7732 22.2751 90.3821 21.5367C89.0202 20.7965 87.423 20.4264 85.5888 20.4264C83.432 20.4264 81.472 20.9442 79.7107 21.9798C77.9787 23.0153 76.5967 24.494 75.5647 26.4175C74.5619 28.341 74.0605 30.5453 74.0605 33.0304C74.0605 35.5154 74.5619 37.7799 75.5647 39.7326C76.5967 41.6561 77.9805 43.1493 79.7107 44.2159C81.472 45.2806 83.4155 45.813 85.5433 45.813C87.3774 45.813 88.991 45.4429 90.3803 44.7027C91.7988 43.9333 92.9073 42.9724 93.7077 41.8202V45.4265H100.685V14.2456H93.7077H93.7095ZM92.8453 36.9321C92.2673 37.9713 91.4888 38.779 90.5079 39.357C89.527 39.9058 88.4732 40.1792 87.3464 40.1792C86.2197 40.1792 85.2096 39.8912 84.2287 39.3132C83.2752 38.7352 82.4966 37.913 81.8913 36.8446C81.3134 35.747 81.0253 34.478 81.0253 33.034C81.0253 31.59 81.3134 30.3502 81.8913 29.311C82.4966 28.2425 83.277 27.4349 84.2287 26.8861C85.1804 26.3373 86.2197 26.0638 87.3464 26.0638C88.4732 26.0638 89.5252 26.3519 90.5079 26.9298C91.4888 27.4786 92.2692 28.2863 92.8453 29.3547C93.4233 30.394 93.7113 31.6502 93.7113 33.1215C93.7113 34.5929 93.4214 35.8637 92.8453 36.9321Z"
        fill="#040000"
      />
      <path
        d="M121.041 37.6399C120.782 38.1139 120.448 38.5387 120.036 38.9125C118.962 39.9226 117.646 40.4276 116.09 40.4276C114.619 40.4276 113.376 39.939 112.356 38.9581C111.366 38.01 110.759 36.679 110.531 34.9652H128.508C128.627 34.2596 128.687 33.4683 128.687 32.5877C128.687 30.1902 128.157 28.0752 127.095 26.241C126.065 24.3777 124.594 22.941 122.683 21.9364C120.803 20.93 118.665 20.4268 116.269 20.4268C113.873 20.4268 111.583 20.9446 109.674 21.9802C107.764 23.0157 106.278 24.4944 105.217 26.4179C104.156 28.3414 103.625 30.5749 103.625 33.1201C103.625 35.6653 104.156 37.855 105.217 39.7767C106.309 41.7002 107.809 43.1953 109.72 44.26C111.662 45.2956 113.844 45.8134 116.271 45.8134C119.274 45.8134 121.806 45.0586 123.868 43.5508C125.93 42.012 127.325 40.0593 128.053 37.6909L128.062 37.6381H121.042L121.041 37.6399ZM112.356 27.005C113.376 26.0988 114.619 25.6467 116.09 25.6467C117.646 25.6467 118.962 26.1134 120.036 27.0469C121.112 27.9512 121.662 29.1692 121.691 30.6952H110.531C110.757 29.1108 111.366 27.8801 112.356 27.005Z"
        fill="#040000"
      />
      <path
        d="M137.727 37.6507C137.842 38.5969 138.287 39.3809 139.063 40.0027C139.867 40.6244 140.859 40.9343 142.039 40.9343C143.218 40.9343 144.079 40.6973 144.712 40.2233C145.374 39.7492 145.704 39.1439 145.704 38.4037C145.704 37.6051 145.301 37.0126 144.497 36.6279C143.72 36.214 142.405 35.7709 140.681 35.2969C138.762 34.8539 137.184 34.3944 135.947 33.9204C134.74 33.4463 133.688 32.7225 132.791 31.7452C131.925 30.7698 131.491 29.4516 131.491 27.7943C131.491 26.4324 131.893 25.1907 132.698 24.0658C133.533 22.9409 134.709 22.053 136.226 21.4021C137.772 20.7512 139.583 20.4248 141.656 20.4248C144.719 20.4248 147.164 21.165 148.989 22.6437C150.814 24.0931 151.82 26.0604 152.006 28.5473H145.352C145.268 27.57 144.878 26.8025 144.179 26.2391C143.508 25.6465 142.602 25.3512 141.457 25.3512C140.396 25.3512 139.572 25.559 138.987 25.9729C138.429 26.3868 138.15 26.9647 138.15 27.705C138.15 28.5327 138.54 29.169 139.322 29.6139C140.104 30.0278 141.496 30.4562 143.142 30.9011C144.998 31.3441 146.531 31.8036 147.736 32.2776C148.943 32.7517 149.979 33.4901 150.847 34.4965C151.744 35.4719 152.209 36.7755 152.24 38.4019C152.24 39.8222 151.822 41.0948 150.987 42.2179C150.183 43.3428 149.007 44.2307 147.459 44.8816C145.944 45.5033 144.165 45.8133 142.123 45.8133C140.081 45.8133 138.132 45.4578 136.46 44.7485C134.79 44.0083 133.459 43.0183 132.469 41.7748C131.51 40.5314 130.983 39.1567 130.892 37.647H137.731L137.727 37.6507Z"
        fill="#040000"
      />
    </svg>
  );
};

export const DownArrows = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M20.5693 2.65869L11.9987 11.2293"
          stroke="#0D50F2"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M12 11.2297L3.42941 2.65914"
          stroke="#0D50F2"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M20.5693 14.2588L11.9987 22.8294"
        stroke="#0D50F2"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M12 22.8293L3.42941 14.2588"
        stroke="#0D50F2"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const BtnArrow = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 21L38 21" stroke="#040000" strokeWidth="5.14652" />
      <path
        d="M21 4L37.9999 21.0001L21 38"
        stroke="#040000"
        strokeWidth="5.14652"
      />
    </svg>
  );
};

export const SendBtnIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.7344 8L7.73438 25.0261L29.4298 30.9183M52.7344 8L35.2189 53L29.4298 30.9183M52.7344 8L29.4298 30.9183"
        stroke="white"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};
