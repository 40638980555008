import { useEffect, useState } from "react";
import { BtnArrow, DownArrows } from "../components/style/icon/hades.iamges";
import {
  BgTextWrap,
  Container,
  HideContents,
  MainBtBox,
  VirtualAreaDiv,
} from "../components/style/styled.components";
import { motion, useAnimation } from "framer-motion";
import ParallaxText from "../components/style/parallax.text";
import { Link } from "react-router-dom";
import MobileHideContent from "../components/mobile/mobile.hide.contents";

const HomeView = () => {
  const scrollDivControls = useAnimation(); // 애니메이션 컨트롤
  const [scrollStatus, setScrollStatus] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 30) {
        // 스크롤이 100px 이상 내려가면 애니메이션 실행
        scrollDivControls.start({ y: 0, opacity: 1 });
        setScrollStatus(false);
      } else {
        // 초기 상태로 돌아감
        scrollDivControls.start({ y: 500, opacity: 0 });
        setScrollStatus(true);
      }
    };

    // 초기 뷰포트 너비 확인
    const isMobile = window.innerWidth < 768;

    console.log(isMobile);

    if (!isMobile) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      if (!isMobile) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollDivControls]);

  return (
    <>
      <Container
        initial={{ opacity: 0 }} // 초기 위치와 투명도
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }} // 애니메이션 속도
      >
        <BgTextWrap>
          {/* <p>Happy Developers</p> */}
          <ParallaxText baseVelocity={-2}>Happy Developers</ParallaxText>
        </BgTextWrap>
        <div className="mobile-contents">
          <p>행복한 개발자들이 모이는 곳, 하데스</p>
        </div>
        <MobileHideContent />
        <MainBtBox>
          {scrollStatus && (
            <>
              <p>Scroll Down</p>
              <div className="down-scroll-arrow">
                <motion.div
                  initial={{ y: 0 }} // 시작 위치
                  animate={{ y: 10 }} // 이동 위치
                  transition={{
                    repeat: Infinity, // 무한 반복
                    repeatType: "reverse", // 왕복 애니메이션
                    duration: 0.5, // 1초 동안 애니메이션
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* 화살표 이미지 */}
                  <DownArrows />
                </motion.div>
              </div>
            </>
          )}
          <span className="copy-right">©Hades. All rights reserved. </span>
        </MainBtBox>
      </Container>
      {/* 가상 스크롤 영역 */}
      <VirtualAreaDiv />
      <HideContents
        className="scroll-div"
        initial={{ y: 500, opacity: 0 }} // 초기 위치와 투명도
        animate={scrollDivControls} // useAnimation으로 제어
        transition={{ duration: 0.5 }} // 애니메이션 속도
      >
        <div className="grid-container">
          <div className="grid-item portfolio">
            <Link to={"/portfolio"}>
              <p>{`상상하던 꿈을 현실로 초대한 \n프로젝트를 소개합니다.`}</p>
              <div className="box">
                <h2>Portfolio</h2>
                <span>
                  <BtnArrow />
                </span>
              </div>
            </Link>
          </div>
          <div className="grid-item about">
            <Link to={"/about"}>
              <p>{`행복한 개발자들이 모이는 곳, \n하데스를 소개합니다.`}</p>
              <div className="box">
                <h2>About</h2>
                <span>
                  <BtnArrow />
                </span>
              </div>
            </Link>
          </div>
          <div className="grid-item product">
            <Link to={"/product"}>
              <p>{`하데스 자체 개발 APP \n"마을회관"을 소개합니다.`}</p>
              <div className="box">
                <h2>Product</h2>
                <span>
                  <BtnArrow />
                </span>
              </div>
            </Link>
          </div>
          <div className="grid-item contact">
            <Link to={"/contact"}>
              <p>{`궁금한 건 뭐든지 \n물어보세요.`}</p>
              <div className="box">
                <h2>Contact</h2>
                <span>
                  <BtnArrow />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </HideContents>
    </>
  );
};

export default HomeView;
