export const items = [
  {
    title: "꿈도깨비",
    subText: "보호대상아동 성장지원 App&Web",
    infoText:
      "교보생명과 초록우산 어린이 재단이 주관하는 보호 \n대상아동 성장지원 앱 및 관리 웹 플랫폼 고도화 개발",
    tag: "App&Web",
    img: "/assets/portfolio1.png",
    link: "https://play.google.com/store/apps/details?id=com.kyobo.dreamdkb&hl=ko",
  },
  {
    title: "블루존클라우드",
    subText: "클라우드 백업 스토리지 Web",
    infoText:
      "중소기업 업무 환경에 적합한 자유로운 공유 기능 및 \n데이터 보안 솔루션 개발",
    tag: "Web",
    img: "/assets/portfolio2.png",
    link: "https://www.bluezonecloud.com/main",
  },
  {
    title: "AI 충녕",
    subText: "세종시청 민원정보 챗봇 반응형 Web",
    infoText:
      "생성형 인공지능 머신이 세종시 민원 정보를 스스로 \n학습하여 답변을 제공하는 챗봇 시스템 개발",
    tag: "Web",
    img: "/assets/portfolio3.png",
    link: "http://175.106.96.66:5173/",
  },
  {
    title: "A-IoT",
    subText: "디바이스 데이터 관리 Web",
    infoText: "다양한 네트워크/디바이스 환경을 수용한 IoT Web \n플랫폼 개발",
    tag: "Web",
    img: "/assets/portfolio4.png",
    link: "https://aiot.keyclops.com/login",
  },
  {
    title: "데일리콘(DailyCon)",
    subText: "레미콘 회사 관리 App&Web",
    infoText:
      "레미콘 회사의 업무 발주 관리 및 레미콘 차량 기사의 \n업무 수주 관리 시스템 개발",
    tag: "App&Web",
    img: "/assets/portfolio5.png",
    link: "http://ha-des.co.kr:18080",
  },
  {
    title: "Labeling Tool",
    subText: "이미지 비식별화/데이터 라벨링 Web",
    infoText:
      "이미지에 흐림효과를 적용하여 비식별화하고, 지정된 \n영역에 데이터 라벨링 할 수 있는  Web 시스템 개발",
    tag: "Web",
    img: "/assets/portfolio6.png",
    link: "http://ha-des.co.kr:3000/login",
  },
];
