import ContactGrid from "../components/contact.grid";
import MapComponents from "../components/map";
import { ContactContainer } from "../components/style/styled.components";

const ContactView = () => {
  return (
    <ContactContainer
      initial={{ opacity: 0 }} // 초기 위치와 투명도
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // 애니메이션 속도
    >
      <div className="inner">
        <div>
          <h2>Contact Us</h2>
        </div>
        <ContactGrid />
        <MapComponents />
      </div>
    </ContactContainer>
  );
};

export default ContactView;
