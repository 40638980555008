export const egPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})/; //핸드폰 정규식

export const formatPhoneNumber = (value: string) => {
  const onlyNumbers = value.replace(/\D/g, ""); // 숫자만 남기기
  if (onlyNumbers.length <= 3) return onlyNumbers;
  if (onlyNumbers.length <= 7)
    return `${onlyNumbers.slice(0, 3)}-${onlyNumbers.slice(3)}`;
  return `${onlyNumbers.slice(0, 3)}-${onlyNumbers.slice(
    3,
    7
  )}-${onlyNumbers.slice(7, 11)}`;
};
