import { motion, useScroll, useTransform } from "framer-motion";
import {
  AboutContainer,
  AboutSectionOne,
  AboutSectionTwo,
} from "../components/style/styled.components";
import Process from "../components/process";
import Team from "../components/team";

const AboutView = () => {
  const { scrollYProgress } = useScroll(); // 스크롤 진행 상태 가져오기
  const isMobile = window.innerWidth < 768;

  // 두 번째 섹션의 y 위치를 스크롤에 따라 조정
  const secondSectionY = useTransform(scrollYProgress, [0.5, 1], [100, 0]);

  return (
    <AboutContainer
      initial={{ opacity: 0 }} // 초기 위치와 투명도
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // 애니메이션 속도
    >
      {/* 첫 번째 섹션 */}
      <motion.div className="first-section">
        <AboutSectionOne>
          <div className="bg-img">
            <img src="/bg-about.png" alt="" />
          </div>
          <div className="about-text">
            <p>Who we are?</p>
            <h2>{`우리는 행복하게 일하고 \n최고의 결과물을 만듭니다.`}</h2>
          </div>
          <div className="mobile-contents">
            <h2>{`우리는 \n행복하게 일하고 \n최고를 만듭니다.`}</h2>
          </div>
        </AboutSectionOne>
      </motion.div>

      {/* 두 번째 섹션 */}
      <motion.div
        style={{
          y: isMobile ? "0" : secondSectionY, // 스크롤에 따라 y값 조정
        }}
      >
        <AboutSectionTwo>
          <div className="section-inner">
            <div className="logo-mean">
              <div className="img-box">
                <img src="/hades-logo-means.png" alt="" />
              </div>
              <div className="text-box">
                <p>행복한 개발자들이 모이는 곳, 하데스</p>
                <ul>
                  <li>행복한 마음으로 최고의 결과물을 만듭니다.</li>
                  <li>행복한 기운을 고객에게 전달하고 신뢰를 만듭니다.</li>
                  <li>
                    고객과의 신뢰를 바탕으로 모두가 존중받는 행복한 관계를
                    만듭니다.
                  </li>
                </ul>
              </div>
            </div>
            <div className="work-box">
              <ul>
                <li>
                  <div className="img-box">
                    <img src="/assets/work1.png" alt="" />
                  </div>
                  <span>프로젝트 기획 및 상담</span>
                </li>
                <li>
                  <div className="img-box">
                    <img src="/assets/work2.png" alt="" />
                  </div>
                  <span>웹 플랫폼 개발</span>
                </li>
                <li>
                  <div className="img-box">
                    <img src="/assets/work3.png" alt="" />
                  </div>
                  <span>운영 인프라 설계</span>
                </li>
                <li>
                  <div className="img-box">
                    <img src="/assets/work4.png" alt="" />
                  </div>
                  <span>앱 개발(Android/iOS)</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="process-box">
            <h2>Our Process</h2>
            <div className="process">
              <Process />
            </div>
          </div>

          <div className="team-box">
            <h2>Our Team</h2>
            <Team />
          </div>
        </AboutSectionTwo>
      </motion.div>
    </AboutContainer>
  );
};

export default AboutView;
