import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeView from "./view/home";
import Layout from "./components/layout/layout";
import { useEffect } from "react";
import PortfolioView from "./view/portfolio";
import AboutView from "./view/about";
import ProductView from "./view/product";
import ContactView from "./view/contact";

function App() {
  useEffect(() => {
    const handleResize = () => {
      // 뷰포트 너비를 기준으로 폰트 크기 계산
      const baseFontSize = 10; // 1920px 기준 기본 폰트 크기
      const newFontSize = `${(window.innerWidth / 1920) * baseFontSize}px`;
      document.documentElement.style.fontSize = newFontSize;
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 초기 실행 시 호출

    return () => {
      window.removeEventListener("resize", handleResize); // 컴포넌트 언마운트 시 이벤트 제거
    };
  }, []);
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeView />} />
          <Route index path="/portfolio" element={<PortfolioView />} />
          <Route index path="/about" element={<AboutView />} />
          <Route index path="/product" element={<ProductView />} />
          <Route index path="/contact" element={<ContactView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
