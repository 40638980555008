import { PortfolioGridBox } from "./style/styled.components";
import { motion } from "framer-motion";

interface ProtfolioDataItem {
  title: string;
  subText: string;
  infoText: string;
  tag: string;
  img: string;
  link: string;
}

interface ProtfolioDataProps {
  data: ProtfolioDataItem[];
}

const PortfolioItems = ({ data }: ProtfolioDataProps) => {
  const linkHandler = (link: string) => {
    window.open(link);
  };
  return (
    <PortfolioGridBox
      initial={{ opacity: 0 }} // 초기 위치와 투명도
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }} // 애니메이션 속도
    >
      {data.map((item, index) => {
        const altText = item.tag.split("/").pop();

        return (
          <motion.div
            className="grid-item"
            key={index}
            data-tag={item.tag}
            initial={{ opacity: 0, y: 20 }} // 항목 초기 위치와 투명도
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }} // 각 항목 순차적 등장
            onClick={() => {
              linkHandler(item.link);
            }}
          >
            <div className="img-item">
              <img src={item.img} alt={altText} />
            </div>
            <div className="sub-item">
              <h5>{item.title}</h5>
              <p>{item.subText}</p>
              <span>{item.infoText}</span>
            </div>
          </motion.div>
        );
      })}
    </PortfolioGridBox>
  );
};

export default PortfolioItems;
