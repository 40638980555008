import { TeamSection } from "./style/styled.components";

const Team = () => {
  return (
    <TeamSection>
      <ul>
        <li>
          <img src="/assets/team1.png" alt="" />
        </li>
        <li>
          <img src="/assets/team2.png" alt="" />
        </li>
        <li>
          <img src="/assets/team3.png" alt="" />
        </li>
        <li>
          <img src="/assets/team4.png" alt="" />
        </li>
      </ul>
    </TeamSection>
  );
};

export default Team;
