import {
  ProcessSection,
  Step,
  StepDescription,
  StepTitle,
  Timeline,
} from "./style/styled.components";

const Process = () => {
  const steps = [
    {
      title: "Explore",
      description: ["사전 미팅", "견적서 전달 및 계약"],
    },
    {
      title: "Design",
      description: ["프로젝트 기획", "화면 UX/UI 디자인", "화면 퍼블리싱"],
    },
    {
      title: "Build",
      description: [
        "Front, Back-end 개발",
        "개발자 & QA 테스트",
        "버그 수정 및 기능 고도화",
      ],
    },
    {
      title: "Improve",
      description: ["고객 검수 및 납품 완료", "유지보수"],
    },
  ];

  return (
    <ProcessSection>
      <Timeline>
        {steps.map((step, index) => (
          <Step key={index}>
            <div className="circle-box">
              <span className="lg-circle"></span>
              <span className="sm-circle"></span>
            </div>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>
              {step.description.map((desc, i) => (
                <li key={i}>{desc}</li>
              ))}
            </StepDescription>
          </Step>
        ))}
      </Timeline>
    </ProcessSection>
  );
};

export default Process;
