import { motion, useAnimation } from "framer-motion";
import { HadesLodo, HadesText } from "../style/icon/hades.iamges";
import { HeaderWrap } from "../style/styled.components";
import MenuToggle from "../style/toogle.menu";
import { Navigation } from "./nav";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 240px 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // 상태를 useState로 관리
  const location = useLocation();
  const navi = useNavigate();
  const scrollDivControls = useAnimation(); // 애니메이션 컨트롤
  const [scrollStatus, setScrollStatus] = useState(false);

  useEffect(() => {
    // 경로 변경 시 isOpen 초기화
    if (isOpen) {
      setIsOpen(false); // isOpen을 false로 설정
    }
  }, [location.pathname]); // location.pathname이 변경될 때 실행

  const toggleMenu = () => setIsOpen((prev) => !prev); // 상태 토글 함수

  const goToHome = () => {
    navi("/");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY === 0) {
        // 스크롤이 맨 위에 있을 경우
        setScrollStatus(false);
      } else {
        // 스크롤이 내려갔을 경우
        setScrollStatus(true);
      }
    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener("scroll", handleScroll);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollDivControls]);

  return (
    <HeaderWrap
      style={{
        backdropFilter:
          location.pathname !== "/" && scrollStatus ? "blur(10px)" : "none",
        transition: "backdrop-filter 0.3s ease-in-out", // 애니메이션 추가
      }}
    >
      <div className="logo" onClick={goToHome}>
        <HadesLodo />
      </div>
      <div className="center" onClick={goToHome}>
        <HadesText />
      </div>
      <div style={{ width: "61px" }}></div>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom="100%"
        style={{ overflow: isOpen ? "visible" : "hidden" }}
      >
        <motion.div
          className="navbar"
          variants={sidebar}
          style={{
            backgroundColor: isOpen ? "rgba(4, 0, 0, 0.5)" : "transparent", // isOpen 상태에 따라 배경색 변경
            transition: "background-color 0.3s ease-in-out", // 배경색 전환 애니메이션 추가
            backdropFilter: isOpen ? "blur(10px)" : "none",
          }}
        />
        <Navigation />
        <MenuToggle toggle={toggleMenu} isOpen={isOpen} />
      </motion.nav>
    </HeaderWrap>
  );
};

export default Header;
