import { Controller, useForm } from "react-hook-form";
import { SendBtnIcon } from "./style/icon/hades.iamges";
import { ContactGridContainer } from "./style/styled.components";
import Select from "react-select";
import { useState } from "react";
import { egPhone, formatPhoneNumber } from "../util/util";

interface FormProps {
  name: string;
  email: string;
  call: string;
  subject: string;
  budget: string;
  message: string;
}

const selectValue = [
  { value: "신규", label: "신규" },
  { value: "수정", label: "수정" },
];

const selectBudget = [
  { value: "1천만원 이하", label: "1천만원 이하" },
  { value: "3천만원 이하", label: "3천만원 이하" },
  { value: "5천만원 이하", label: "5천만원 이하" },
  { value: "1억원 이하", label: "1억원 이하" },
  { value: "1억원 초과", label: "1억원 초과" },
];

const ContactGrid = () => {
  const { register, handleSubmit, control, setValue } = useForm<FormProps>();
  const [isSending, setIsSending] = useState(false); // 전송 상태 관리

  const onSubmit = async (data: FormProps) => {
    setIsSending(true); // 전송 시작

    try {
      const response = await fetch("https://ha-des.co.kr:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert("메일이 전송되었습니다.");
      } else {
        alert("메일 전송에 실패하였습니다. 관리자에게 문의해주세요.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred.");
    } finally {
      setIsSending(false); // 전송 종료
    }
  };

  return (
    <ContactGridContainer>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid-item name">
          <div className="input-box">
            <label htmlFor="formName">Name</label>
            <input
              id="fomrName"
              type="text"
              placeholder="회사명/담당자명"
              {...register("name", { required: true })}
            />
          </div>
        </div>
        <div className="grid-item email">
          <div className="input-box">
            <label htmlFor="fomrEmail">E-mail</label>
            <input
              id="fomrEmail"
              type="text"
              placeholder="abc123@mail.com"
              {...register("email", { required: true })}
            />
          </div>
        </div>
        <div className="grid-item call">
          <div className="input-box">
            <label htmlFor="fomrCall">Call</label>
            <input
              id="fomrCall"
              type="text"
              placeholder="000-0000-0000"
              {...register("call", {
                required: true,
                onChange: (e) => {
                  const formattedValue = formatPhoneNumber(e.target.value);
                  setValue("call", formattedValue); // 입력 값을 포맷 후 업데이트
                },
              })}
            />
          </div>
        </div>
        <div className="grid-item send">
          <button disabled={isSending}>
            <span className="icon-box">
              <SendBtnIcon />
            </span>
            <span>{isSending ? "전송 중..." : "Send"}</span>
          </button>
        </div>
        <div className="grid-item subject">
          <div className="input-box">
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <Select
                  className="select-content"
                  classNamePrefix="react-select"
                  options={selectValue}
                  onChange={(val) => field.onChange(val ? val.value : null)}
                  value={selectValue.find((c) => c.value === field.value)}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder="subject"
                  maxMenuHeight={200}
                  isSearchable={false} // 검색 기능 비활성화
                />
              )}
            />
          </div>
        </div>
        <div className="grid-item budget">
          <div className="input-box">
            <Controller
              name="budget"
              control={control}
              render={({ field }) => (
                <Select
                  className="select-content"
                  classNamePrefix="react-select"
                  options={selectBudget}
                  onChange={(val) => field.onChange(val ? val.value : null)}
                  value={selectBudget.find((c) => c.value === field.value)}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder="Budget"
                  maxMenuHeight={200}
                  isSearchable={false} // 검색 기능 비활성화
                />
              )}
            />
          </div>
        </div>
        <div className="grid-item message">
          <div className="input-box">
            <label htmlFor="fomrMessage">Message</label>
            <textarea
              id="fomrMessage"
              placeholder="문의 내용"
              {...register("message", { required: true })}
            />
          </div>
        </div>
      </form>
    </ContactGridContainer>
  );
};

export default ContactGrid;
