import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  //bg
  bgColor: "#FFF",
  signatureColor: "#0D50F2",
  blackColor: '#040000',

  // text
  mainTextGray: "rgba(129, 128, 128, 1)",
  portfolioTitleBlack: "#040000",
  portfolioTextGray: "#4F4D4D",
  PortfolioSmTextGray: "#818080",
};
