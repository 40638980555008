import { useEffect } from "react";
import Header from "./header";
import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Layout = () => {
  const location = useLocation(); // 현재 경로 가져오기

  useEffect(() => {
    // 페이지 이동 시 스크롤을 맨 위로 이동
    window.scrollTo(0, 0);
  }, [location.pathname]); // 경로 변경될 때 실행

  const pageTitle = location.pathname.replace("/", "") || "Home";

  return (
    <div>
      <Helmet>
        <title>Hades | {pageTitle}</title>

        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
